export function isNullOrWhitespace(input: any): boolean {
  if (typeof input === 'undefined' || input == null) {
    return true;
  }

  return input.toString().replace(/\s/g, '').length < 1;
}

export const isEmailValid = (incomingEmail: any) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    incomingEmail
  );
};
