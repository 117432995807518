import { CurrencyTypeMissing } from '@/src/interfaces/missing-types';

const fmt = (
  number: number | undefined,
  currency: CurrencyTypeMissing,
  showCurrency = true,
  asSymbol = false,
  minimumFractionDigits = 2
) => {
  if (number !== undefined) {
    let formattedNumber;
    if (CurrencyTypeMissing.TRY === currency) {
      formattedNumber = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits,
      })
        .format(number)
        .split('')
        .splice(1)
        .join('');
    } else if (CurrencyTypeMissing.USD === currency) {
      formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits,
      })
        .format(number)
        .split('')
        .splice(1)
        .join('');
    } else if (CurrencyTypeMissing.EUR === currency) {
      formattedNumber = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits,
      })
        .format(number)
        .split('')
        .slice(0, -2)
        .join('');
    } else {
      formattedNumber = number.toString();
    }

    switch (currency) {
      case CurrencyTypeMissing.USD: {
        if (showCurrency) {
          if (asSymbol) {
            return `$${formattedNumber}`;
          } else {
            return `${formattedNumber} USD`;
          }
        } else {
          return formattedNumber;
        }
      }
      case CurrencyTypeMissing.EUR: {
        if (showCurrency) {
          if (asSymbol) {
            return `€${formattedNumber}`;
          } else {
            return `${formattedNumber} EUR`;
          }
        } else {
          return formattedNumber;
        }
      }
      case CurrencyTypeMissing.TRY: {
        if (showCurrency) {
          if (asSymbol) {
            return `₺${formattedNumber}`;
          } else {
            return `${formattedNumber} TL`;
          }
        } else {
          return formattedNumber;
        }
      }
      default:
        return formattedNumber;
    }
  }
};

export default fmt;
