'use client';
import { useEffect } from 'react';

const RefreshAds = () => {
  useEffect(() => {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(() => {
      googletag.pubads().refresh();
    });
  }, []);
  return <></>;
};

export default RefreshAds;
