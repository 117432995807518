import { PercentageBadgeIcon } from '@/src/features/ui/icons/icons';
import { ResponseStoreFrontModuleCreditCardHeroCampaign } from '@/src/interfaces/data-contracts';

type Props = {
  heroCampaigns: ResponseStoreFrontModuleCreditCardHeroCampaign[];
};
export const CampaignContainer: React.FC<Props> = ({ heroCampaigns }) => {
  return (
    <div className="p-3 rounded-md bg-orange500/5 flex flex-col text-xs text-orange500 font-semibold gap-3">
      {heroCampaigns.map((campaign) => (
        <div key={campaign.campaignId} className="flex gap-2 items-center">
          <PercentageBadgeIcon width={20} height={20} className="stroke-orange500" />
          {campaign.title}
        </div>
      ))}
    </div>
  );
};
