import classNames from 'classnames';
import { RelLink } from '../../ui/rel-link';
type Props = {
  href: string;
  text: string;
  external?: boolean;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void | null;
};

export const LinkLikeButton: React.FC<Props> = ({
  href,
  text,
  external,
  className,
  onClick,
  disabled,
  children,
  ...rest
}) => {
  // const href = props.href;
  // const text = props.text;
  const _external = external === undefined ? false : external;
  // const className = props.className;
  // const onClick = props.onClick;

  return (
    <RelLink
      {...rest}
      onClick={onClick}
      href={href}
      className={classNames(
        'flex h-[48px] items-center justify-center rounded-lg border border-purple500 text-sm font-bold md:text-base',
        _external ? 'bg-white text-purple500' : 'bg-purple500 text-white',
        { 'pointer-events-none': disabled },
        className
      )}
      {...rest}
    >
      {text}
      {children}
    </RelLink>
  );
};
