export enum CurrencyTypeMissing {
  TRY,
  USD,
  EUR,
}

export enum PosTypeEnumMissing {
  Physical,
  Virtual,
  Digital,
  Mobile,
}

export enum ModuleTypeMissing {
  PopularCredit = 1,
  BestCreditCard = 2,
  Banking = 3,
  DepositProduct = 4,
  MostPreferred = 5,
  ModuleWithContent = 6,
  Calculate = 19,
}

export enum WidgetTypeMissing {
  FinanceWritings = 1,
  CustomerComments = 2,
  AwardsAndCertificates = 3,
  SeoWritings = 4,
  FAQ = 5,
  BlogCorrectConsumerLoanCalculation = 6,
  BlogParticipationBanking = 8,
  ScoreAboutArticle = 11,
  WhatIsScore = 12,
  CreditTypes = 13,
  ExpertComments = 14,
  Notifications = 30,
}

export interface ISendOtpRequest {
  userId?: string;
  email?: string;
  phoneNumber: string;
  userTrackingId?: string;
  sourceType?: string;
  sourceId?: string;
}

export interface ISendOtpResponse {
  data: boolean;
  errors: any[];
  hasError: boolean;
  isSuccess: boolean;
}

export interface IVerifyOtpRequest {
  code: string;
}

export interface IVerifyOtpResponse extends ISendOtpResponse {}

export type UserAgent = {
  browser: string;
  deviceType?: any;
  os: string;
  engine: string;
  isMobile: boolean;
  isTablet: boolean;
  isIos: boolean;
  source: string;
  deviceVendor?: any;
  osVersion: number;
  browserVersion: number;
  engineVersion: number;
  isIphone: boolean;
  isIpad: boolean;
  isDesktop: boolean;
  isChrome: boolean;
  isFirefox: boolean;
  isSafari: boolean;
  isIE: boolean;
  isEdge: boolean;
  isOpera: boolean;
  isMac: boolean;
  isChromeOS: boolean;
  isWindows: boolean;
  isAndroid: boolean;
  isBot: boolean;
};

export enum OperatingSystemType {
  All = 0,
  Android = 1,
  iOS = 2,
  Windows = 3,
}

export enum DeviceEnum {
  All = 'All',
  Desktop = 'Desktop',
  Mobile = 'Mobile',
  Tablet = 'Tablet',
}

export enum DeviceKeywordsEnum {
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  Desktop = 'Desktop',
}
export enum PosSubscriptionPeriodEnumMissing {
  Daily,
  Weekly,
  Monthly,
}

export enum ProductType {
  Month = 0,
  Sponsor = 1,
  Normal = 2,
  SpecialRate = 3,
}
