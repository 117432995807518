import Link from 'next/dist/client/link';
import Image from 'next/image';

type Props = {
  name: string;
  logoPath: string;
  parentPath?: string | null;
  seoUrl?: string;
};

export const ListItem: React.FC<Props> = (props) => {
  const { name, logoPath, seoUrl, parentPath } = props;
  return (
    <li
      className="flex h-[66px] w-[104px] items-center justify-center rounded-lg border border-black300 md:h-[80px] md:w-[160px]"
      data-testid="bankCard"
    >
      {seoUrl && parentPath ? (
        <Link
          href={`${parentPath}/${seoUrl}`}
          className="flex h-full w-full items-center justify-center"
          data-testid="bankLink"
        >
          <span className="flex h-[35px] w-[70px] md:h-[50px] md:w-[100px]">
            <Image
              quality={80}
              src={logoPath}
              key={logoPath}
              alt={name + ' Kobi Kredisi'}
              sizes={`${logoPath} 1200w,  ${logoPath}?w=200 200w, ${logoPath}?w=400 400w,  ${logoPath}?w=800 800w,  ${logoPath}?w=1024 1024w`}
              decoding="async"
              loading="lazy"
              width={100}
              height={50}
              data-testid="bankImage"
            />
          </span>
        </Link>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <span className="flex h-[35px] w-[70px] md:h-[50px] md:w-[100px]">
            <Image
              quality={80}
              src={logoPath}
              key={logoPath}
              alt={name + ' Kobi Kredisi'}
              sizes={`${logoPath} 1200w,  ${logoPath}?w=200 200w, ${logoPath}?w=400 400w,  ${logoPath}?w=800 800w,  ${logoPath}?w=1024 1024w`}
              decoding="async"
              loading="lazy"
              width={100}
              height={50}
              data-testid="bankImage"
            />
          </span>
        </div>
      )}
    </li>
  );
};
