'use client';
import { CustomRequestEvent, DataLayerService } from '@/lib/data-layer/data-layer';
import { DataLayerEventCategoryEnum, EventAction } from '@/lib/data-layer/types';
import { mockDataTabs } from './mock-data-tabs';
import { InnerContainer } from '@/src/features/ui/common/inner-container';
import { Card } from './card';
import { useState } from 'react';
import classNames from 'classnames';
import { ResponseMenuItemList } from '@/src/interfaces/data-contracts';

const dataLayer = new DataLayerService();
export const Links = ({
  eventAction,
  heroDescription,
  menuItems,
}: {
  eventAction: EventAction;
  heroDescription: string;
  menuItems: ResponseMenuItemList['metroItems'];
}): JSX.Element => {
  const [activeTab, setActiveTab] = useState(0);
  const handleClickEvent = (eventLabel: string) => {
    dataLayer.sendEvent(new CustomRequestEvent(eventLabel, eventAction, DataLayerEventCategoryEnum.WIDGET_CLICK, '-'));
  };
  return (
    <InnerContainer>
      <div className="promo-tabs flex justify-center md:gap-8" data-testid="tabs">
        {menuItems &&
          menuItems.map((item, idx) => (
            <button
              className={classNames(
                'z-10 cursor-pointer select-none rounded-[90px] border border-transparent px-5 py-[10px] text-center text-sm hover:border hover:border-purple200 hover:bg-purple100 md:whitespace-nowrap',
                [
                  activeTab === idx
                    ? 'bg-purple404 text-white hover:border-transparent hover:bg-purple404'
                    : 'text-black',
                ]
              )}
              key={item.id}
              onClick={() => setActiveTab(idx)}
              data-testid="tabButton"
            >
              {item.name}
            </button>
          ))}
      </div>
      <div className="tab-content mt-8">
        {menuItems &&
          menuItems.map((menuItem, idx) => (
            <div
              key={idx}
              className={classNames('overflow-auto text-sm text-black800 md:text-base', {
                hidden: activeTab !== idx,
              })}
              data-testid="tabContent"
            >
              <ul
                className={classNames('flex flex-wrap gap-3 md:flex-row md:flex-nowrap  md:justify-center md:gap-8', {
                  'justify-between': menuItem.subMenuItemList && 1 < menuItem.subMenuItemList.length,
                  'justify-center': menuItem.subMenuItemList && 1 === menuItem.subMenuItemList.length,
                })}
                data-testid="cardList"
              >
                {menuItem &&
                  menuItem.subMenuItemList &&
                  menuItem.subMenuItemList.map((subItem, index) => {
                    return (
                      <Card
                        onClick={() => handleClickEvent(subItem.name ?? '-')}
                        item={subItem}
                        key={index}
                        index={index}
                      />
                    );
                  })}
              </ul>
              <p className="mt-8 text-center text-sm md:px-[200px]" data-testid="description">
                {heroDescription}
              </p>
            </div>
          ))}
      </div>
    </InnerContainer>
  );
};
